// store/modalStore.js

import {defineStore} from 'pinia'
import {
  classicheConfig,
  generalConfig,
  mediaHubConfig,
  sectorsConfig,
} from '~/globals/forms.js'

export const useModalStore = defineStore({
  id: 'modal',
  state: () => ({
    currentPage: '/',
    formFields: generalConfig,
    legalEntityModality: 'empty', // empty (oscurata), dynamic (sectors)
    modalVisible: false,
  }),
  getters: {
    getCurrentPage() {
      return this.currentPage
    },
    getLegalEntityModality() {
      return this.legalEntityModality
    },
    getModalVisible() {
      return this.modalVisible
    },
    getFormFields() {
      return this.formFields
    },
  },
  actions: {
    closeModal() {
      this.modalVisible = false
    },
    openModal() {
      this.modalVisible = true
    },
    openModalAction(action) {
      if (action === 'project') {
        // page-based configuration
        switch (page) {
          case 'classiche-page':
            this.setFormFields(classicheConfig)
            this.setLegalEntityModality('empty')
            break
          case 'mediahub-page':
            this.setFormFields(mediaHubConfig)
            this.setLegalEntityModality('empty')
            break
          case 'sector-page':
            this.setFormFields(sectorsConfig)
            this.setLegalEntityModality('dynamic')
            break
          default:
            this.setFormFields(generalConfig)
            this.setLegalEntityModality('empty')
            break
        }
      } else if (action === 'general') {
        // general configuration
        this.setFormFields(generalConfig)
        this.setLegalEntityModality('empty')
      }

      // open modal
      this.modalVisible = true
    },
    setCurrentPage(currentPage) {
      this.currentPage = currentPage
    },
    setFormFields(formFields) {
      this.formFields = formFields
    },
    setLegalEntityModality(modality) {
      this.legalEntityModality = modality
    },
  },
})
